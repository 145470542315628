#featured {
  position: relative;
  height: 110vh;
  background-color: #000000;
}

.featured-item {
  visibility: hidden;
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.featured-item.active {
  visibility: visible;
}

@media screen and (max-width: 1280px) and (orientation: portrait) {
  #featured {
    height: 100vh;
    .featured-item {
      top: 55%;
      width: 200%;
    }
  }
}

@media screen and (max-width: 1280px) and (orientation: landscape) {
  .featured-item {
    top: 40%;
    width: 250%;
  }
}

@media screen and (min-width: 1280px) {
  .featured-item {
    width: 100%;
    top: 40%;
  }
}
