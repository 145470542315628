#embedded-player {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150vh;
  width: 100vw;
  background-color: black;

  .times {
    position: absolute;
    opacity: 0.7;
    z-index: 1;
    &:hover {
      opacity: 1;
      transition: 300ms linear;
    }
    img {
      filter: invert(1);
    }
  }

  .embedded-player {
    width: 70%;
    height: 45%;

    .player-text {
      width: 100%;
      text-align: center;
      p {
        color: white;
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 1280px) and (orientation: portrait) {
  #embedded-player {
    height: 100vh;

    .embedded-player {
      padding-top: 45%;
      iframe {
        height: auto;
        width: 100%;
      }
    }

    .player-text p {
      padding: 9px;
    }

    .times {
      width: 7vw;
      top: 5%;
      right: 7%;
    }
  }
}

@media screen and (max-width: 1280px) and (orientation: landscape) {
  .embedded-player {
    margin-top: 8%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .player-text p {
    padding: 7px;
  }

  #embedded-player .studio-logo {
    visibility: hidden;
  }

  #embedded-player .page-indicator {
    visibility: hidden;
  }

  .times {
    width: 3vw;
    top: 5%;
    right: 4%;
  }
}

@media screen and (min-width: 1280px) {
  .embedded-player {
    margin-top: 8%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .player-text p {
    padding: 7px;
  }
  .times {
    width: 3vw;
    top: 5%;
    right: 4%;
  }
}
