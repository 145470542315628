@keyframes flash-text {
  0% {
    opacity: 0.9;
  }
  10% {
    opacity: 0.7;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.9;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.7;
  }
  60% {
    opacity: 0.8;
  }
  70% {
    opacity: 0.9;
  }
  80% {
    opacity: 0.7;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.7;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.9;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.9;
  }
  60% {
    opacity: 0.7;
  }
  70% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
