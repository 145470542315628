.card {
  visibility: hidden;
}

.card.active {
  visibility: visible;
  position: absolute;
  color: white;
  font-family: "Roboto", sans-serif;
}

.MuiCircularProgress-root {
  position: absolute;
  bottom: 0em;
  left: 0%;
}

.MuiCircularProgress-svg {
  color: pink;
  opacity: 0.4;
  width: 75%;
}

@media screen and (max-width: 1280px) and (orientation: portrait) {
  .card.active {
    bottom: 14%;
    left: 10%;
    h3 {
      transform: translate(2rem, -50%);
      font-size: 1rem;
      font-weight: 300;
    }
  }
}

@media screen and (max-width: 1280px) and (orientation: landscape) {
  .card.active {
    left: 5%;
    bottom: 20%;
    h3 {
      transform: translate(3rem);
      font-size: 1.5rem;
      font-weight: 300;
    }
  }
}

@media screen and (min-width: 1280px) {
  .card.active {
    left: 5%;
    bottom: 20%;
    h3 {
      transform: translate(3rem);
      font-size: 1.5rem;
      font-weight: 300;
    }
  }
}
