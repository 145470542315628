#contact {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .contact-img {
    position: sticky;
    top: 0;
    height: 100vh;
    img {
      position: absolute;
      height: 100%;
      width: 50%;
      right: 0;
      object-fit: cover;
    }
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 0.7;
  transform: none;
  visibility: visible;
}

.contact-buttons {
  position: sticky;
  display: flex;

  li {
    float: left;
    margin-right: 30px;
    list-style-type: none;
    a img {
      opacity: 0.75;
      display: block;
      border: none;
      filter: invert(1);
    }
    a img:hover {
      opacity: 1;
      transition: 400ms ease-in-out;
      filter: invert(1);
    }
  }
}

.arrow {
  position: absolute;
  left: 50%;
  transform: translate(-50%) rotate(180deg);
  z-index: 1;
  span {
    margin-top: -15px;
    display: block;
    width: 25px;
    height: 25px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    animation: buttonbounce 2.5s infinite;
  }
  span:nth-child(2) {
    animation-delay: -0.1s;
  }
  span:nth-child(3) {
    animation-delay: -0.2s;
  }
}

@keyframes buttonbounce {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

@media screen and (max-width: 1280px) and (orientation: portrait) {
  #contact {
    height: 100vh;
    .contact-img {
      img {
        width: 100%;
      }
    }
  }

  .quote-container1 {
    display: none;
  }

  .contact-buttons {
    position: absolute;
    justify-content: center;
    width: 100%;
    bottom: 10vh;
    li a img {
      width: 42px;
      height: 42px;
    }
  }

  .arrow {
    display: none;
  }

  .footer-container {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (orientation: landscape) {
  #contact {
    .quote-container1 {
      padding-top: 25vh;
      padding-bottom: 25vh;
      color: white;
      h3 {
        font-size: 1rem;
        font-family: "Roboto", sans-serif;
        font-style: italic;
      }
      span {
        padding-left: 1rem;
        font-size: 0.75rem;
        font-family: "Roboto", sans-serif;
        font-style: italic;
        font-weight: bold;
      }
    }

    .quote-container2 {
      padding-top: 40vh;
    }

    .quote-container3 {
      padding-top: 65vh;
    }

    .quote1 {
      position: sticky;
      top: 25vh;
      margin-left: 10%;
    }

    .quote2 {
      position: sticky;
      top: 45vh;
      margin-left: 15%;
    }

    .quote3 {
      margin-left: 10%;
    }

    .contact-buttons {
      top: 80vh;
      justify-content: flex-end;
      padding-right: 4rem;
      li a img {
        width: 40px;
        height: 40px;
        opacity: 0.75;
      }
    }

    .arrow {
      bottom: 15vh;
    }

    .footer-container {
      display: none;
    }
  }
}

@media screen and (min-width: 1280px) {
  #contact {
    .quote-container1 {
      padding-top: 25vh;
      padding-bottom: 25vh;
      color: white;
      h3 {
        font-size: 1.5rem;
        font-family: "Roboto", sans-serif;
        font-style: italic;
      }
      span {
        padding-left: 1rem;
        font-size: 1rem;
        font-family: "Roboto", sans-serif;
        font-style: italic;
        font-weight: bold;
      }
    }

    .quote-container2 {
      padding-top: 40vh;
    }

    .quote-container3 {
      padding-top: 65vh;
    }

    .quote1 {
      position: sticky;
      top: 25vh;
      margin-left: 10%;
    }

    .quote2 {
      position: sticky;
      top: 45vh;
      margin-left: 15%;
    }

    .quote3 {
      margin-left: 10%;
    }

    .contact-buttons {
      top: 87vh;
      justify-content: flex-end;
      li a img {
        width: 40px;
        height: 40px;
      }
    }

    .arrow {
      bottom: 8vh;
    }

    .footer-container {
      position: absolute;
      left: 3%;
      bottom: 5vh;
      color: white;
      font-size: 17px;
      font-weight: 200;
    }
  }
}
