#portfolio {
  position: relative;
  // top: -5vh;
}

.project {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75), -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
    0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  span {
    position: absolute;
  }
}

a:active,
a:focus {
  outline: none;
  -moz-outline-style: none;
  outline-style: none;
}
@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75), -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  }
  14% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75), -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  }
  15% {
    text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75), 0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  49% {
    text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75), 0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  50% {
    text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75), 0.05em 0 0 rgba(0, 255, 0, 0.75),
      0 -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  99% {
    text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75), 0.05em 0 0 rgba(0, 255, 0, 0.75),
      0 -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  100% {
    text-shadow: -0.025em 0 0 rgba(255, 0, 0, 0.75), -0.025em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
}

@media screen 
and (max-width: 1280px)
and (orientation: portrait) {
  .project {
    height: 18vh;
    font-size: 1rem;
  }
}

@media screen 
and (max-width: 1280px)
and (orientation: landscape) {
  .project {
    height: 25vh;
    font-size: 2.3rem;
  }

  .project:hover {
    filter: grayscale(70%) hue-rotate(90deg);
    transition: 400ms ease-in-out;
    animation: glitch 500ms infinite;
    cursor: pointer;
    span {
      :first-child {
        animation: glitch 650ms infinite;
        clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
        transform: translate(-0.025em, -0.0125em);
        opacity: 0.8;
      }
      :last-child {
        animation: glitch 375ms infinite;
        clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
        transform: translate(0.0125em, 0.025em);
        opacity: 0.8;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .project {
    height: 25vh;
    font-size: 2.3rem;
  }

  .project:hover {
    filter: grayscale(70%) hue-rotate(90deg);
    transition: 400ms ease-in-out;
    animation: glitch 500ms infinite;
    cursor: pointer;
    span {
      :first-child {
        animation: glitch 650ms infinite;
        clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
        transform: translate(-0.025em, -0.0125em);
        opacity: 0.8;
      }
      :last-child {
        animation: glitch 375ms infinite;
        clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
        transform: translate(0.0125em, 0.025em);
        opacity: 0.8;
      }
    }
  }
}
